import React from "react";
import { Helmet } from 'react-helmet';
import { Link, graphql } from "gatsby";
import * as BS from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BreadcrumbLite } from '../../components/common/BreadcrumbLite'
import styled from 'styled-components'
const RightCol = styled.div`
  text-align: right;
  @media only screen and (max-width: 768px) {
    text-align: left;
  }
`

const Seed = (props) => {


  const renderVC = (edge, index) => {
    if (edge.node.category === 'VC') {
      return (
        <section>
          <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}><BS.Card.Body fluid>
            <BS.Row>
              <BS.Col sm={6}>
                <div>
                  < Link to={`/investor/${edge.node.name.toLowerCase().replace(/ /g, "-")}/`}>{edge.node.name} </Link>
                </div>
              </BS.Col>
              <BS.Col sm={6}><RightCol>
                investments : {edge.node.count}
              </RightCol>
              </BS.Col>
            </BS.Row>
          </BS.Card.Body>
          </BS.Card>
        </section>
      )
    }
  }

  const renderAngel = (edge, index) => {
    if (edge.node.category === 'angel') {
      return (
        <section>

          <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}><BS.Card.Body fluid>
            <BS.Row>
              <BS.Col sm={6}>
                <div>
                  < Link to={`/investor/${edge.node.name.toLowerCase().replace(/ /g, "-")}/`}>{edge.node.name} </Link>
                </div>
              </BS.Col>
              <BS.Col sm={6}><RightCol>
                investments : {edge.node.count}
              </RightCol>
              </BS.Col>
            </BS.Row>
          </BS.Card.Body>
          </BS.Card>
        </section>
      )
    }
  }

  const renderAngelNetwork = (edge, index) => {
    if (edge.node.category === 'angel network') {
      return (
        <section>

          <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}><BS.Card.Body fluid>
            <BS.Row>
              <BS.Col sm={6}>
                <div>
                  < Link to={`/investor/${edge.node.name.toLowerCase().replace(/ /g, "-")}/`}>{edge.node.name} </Link>
                </div>
              </BS.Col>
              <BS.Col sm={6}><RightCol>
                investments : {edge.node.count}
              </RightCol>
              </BS.Col>
            </BS.Row>
          </BS.Card.Body>
          </BS.Card>
        </section>
      )
    }
  }

  const renderFamilyOffice = (edge, index) => {
    if (edge.node.category === 'Family Office') {
      return (
        <section>

          <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}><BS.Card.Body fluid>
            <BS.Row>
              <BS.Col sm={6}>
                <div>
                  < Link to={`/investor/${edge.node.name.toLowerCase().replace(/ /g, "-")}/`}>{edge.node.name} </Link>
                </div>
              </BS.Col>
              <BS.Col sm={6}><RightCol>
                investments : {edge.node.count}
              </RightCol>
              </BS.Col>
            </BS.Row>
          </BS.Card.Body>
          </BS.Card>
        </section>
      )
    }
  }

  const renderCorporateVC = (edge, index) => {
    if (edge.node.category === 'Corporate VC') {
      return (
        <section>

          <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}><BS.Card.Body fluid>
            <BS.Row>
              <BS.Col sm={6}>
                <div>
                  < Link to={`/investor/${edge.node.name.toLowerCase().replace(/ /g, "-")}/`}>{edge.node.name} </Link>
                </div>
              </BS.Col>
              <BS.Col sm={6}><RightCol>
                investments : {edge.node.count}
              </RightCol>
              </BS.Col>
            </BS.Row>
          </BS.Card.Body>
          </BS.Card>
        </section>
      )
    }
  }

  return (

    <section>
      <Helmet>
        <html lang="en" />
        <title>Seed Investors Ranked By Unicorn Alert</title>
        <meta name="title" content="Seed Investors Ranked By Unicorn Alert"></meta>
        <meta name="description" content='Get the latest updates from startups, investors and fundraising in your realtime Unicorn Alert dashboard.' />
      </Helmet>
      <BS.Container style={{ paddingLeft: 0, paddingRight: 0, maxWidth: 1400 }}>
      <BreadcrumbLite>
        <BS.Breadcrumb.Item> <Link to={`/`}>Home</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item> <Link to={`/investor/`}>Investors</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item active>Seed Investors</BS.Breadcrumb.Item>
      </BreadcrumbLite>


      <BS.Row style={{display: 'block'}}>
          <BS.Col sm={12}>
            <h1>Who Invests In Seed Startups?</h1>
            <h2 style={{color: '#34383b', fontWeight: 300, marginBottom: 25}}>Vcs, Angel investors, angel networks and family offices</h2>
            </BS.Col>
        </BS.Row>



        <BS.Row>

          <BS.Col sm={4}>
            <BS.Card style={{ marginTop: 0 }}>
              <BS.Card.Header>VCs</BS.Card.Header>
            </BS.Card>
            {props.data.allInvestor.edges.map(renderVC)}


          </BS.Col>


          <BS.Col sm={4}>
            <BS.Card style={{ marginTop: 0 }}>
              <BS.Card.Header>Angel Investors</BS.Card.Header>
            </BS.Card>
            {props.data.allInvestor.edges.map(renderAngel)}


          </BS.Col>

          <BS.Col sm={4}>
            <BS.Card style={{ marginTop: 0 }}>
              <BS.Card.Header>Angel Networks</BS.Card.Header>
            </BS.Card>
            {props.data.allInvestor.edges.map(renderAngelNetwork)}

            <BS.Card style={{ marginTop: 20 }}>
              <BS.Card.Header>Family Offices</BS.Card.Header>
            </BS.Card>
            {props.data.allInvestor.edges.map(renderFamilyOffice)}

            <BS.Card style={{ marginTop: 20 }}>
              <BS.Card.Header>Corporate VCs</BS.Card.Header>
            </BS.Card>
            {props.data.allInvestor.edges.map(renderCorporateVC)}


          </BS.Col>
        </BS.Row>

      </BS.Container>

    </section >

  );
}

export const query = graphql`
{
  allInvestor(sort: {order: DESC, fields: count}, filter: {round: {eq: "seed"}, count: {gt: 0}}) {
    edges {
      node {
        count
        name
        id
        type
        website
        location
        category
      }
    }
  }
}
`

export default Seed
